import React from 'react';
import './Header.css';
import {APPLE_STORE_LINK, GOOGLE_PLAY_LINK, SITE_URL} from "../../../core/links";

const logo = require('../../../assets/logo.png');
const appleIcon = require('../../../assets/apple.png');
const googleIcon = require('../../../assets/google.png');
const barIcon = require('../../../assets/bar.png');
const appleIconMobile = require('../../../assets/apps/app-store.jpg');
const googleIconMobile = require('../../../assets/apps/play-store.jpg');

function Header({onMobileMenuOpen} : any) {
  return (
    <header className="header">
      <div className="container with-flex-row">
        <a href={'/'} className="header-logo">
          <img src={logo} alt=""/>
        </a>
        <div className="header-right">
          <ul className="header-menu">
            <li><a href={SITE_URL}>About us</a></li>
            <li><a href={SITE_URL}>Blog</a></li>
            <li><a href={SITE_URL}>Contact Us</a></li>
          </ul>
        </div>
        <div className="header-mobile-right">
          <span className="header-bar" onClick={() => {
            onMobileMenuOpen()
          }}>
          <img className="header-bar-icon" src={barIcon} alt=""/>
        </span>
        </div>
      </div>
    </header>
  );
}

export default Header;
