import React, {useState} from 'react';
import './Settings.css';
import {useDispatch, useSelector} from "react-redux";
import Switch from "react-switch";
import {setPhotosInSwitcherAction, userSignOutAction} from "../../reducers/common";
import {Link} from "react-router-dom";
import {apiUserUpdatePhotosInSwitcher} from "../../actions/userActions";
import Modal from "../../components/ui/modal/Modal";
import LocationTab from "./components/LocationTab";
import GeneralInfoTab from "./components/GeneralInfoTab";

const settingsIcon = require('../../assets/settings.png');
const infoIcon = require('../../assets/info.png');

function Settings() {

  const dispatch = useDispatch()

  const token = useSelector((state: any) => state.common.token);
  const photos_in_switcher = useSelector((state: any) => state.common.user.photos_in_switcher);
  const email = useSelector((state: any) => state.common.user.email);
  const [infoModalObj, setInfoModalObj] = useState<any>(null)

  const [loading] = useState(false)

  const logout = () => {
    dispatch(userSignOutAction());
  }

  const setPhotosInSwitcher = (checked: boolean) => {

    apiUserUpdatePhotosInSwitcher(token, +checked)
    dispatch(
      setPhotosInSwitcherAction({
        photos_in_switcher: +checked
      })
    )
  }

  const settingsInfo = (
    <div className="modal-info">
      <span className="modal-info-title">Photos in Switcher</span>
      <span className="modal-info-subtitle">
        When your turn on this function, the icons in the switcher at the bottom
        of the app will become your main profile photos. Enjoy!
      </span>
      <button className="modal-info-button" onClick={() => {
        setInfoModalObj(null)
      }}>Got it</button>
    </div>
  )

  const deleteInfo = (
    <div className="modal-info">
      <span className="modal-info-title">Delete Your Account?</span>
      <span className="modal-info-subtitle">
        You are about to delete your account permanently. Are you certain this is what you want?
      </span>
      <button className="modal-info-button red" onClick={() => {
        setInfoModalObj(null)
      }}>Delete</button>
      <span className="modal-info-cancel-button" onClick={() => {
        setInfoModalObj(null)
      }}>Cancel</span>
    </div>
  )

  return (
    <>
      <div className="content-area-top">
        <h1 className="content-area-title">Settings</h1>
      </div>
      <div className="settings">
        {loading ? (
          <div className="spinner"/>
        ) : (
          <div className="settings-row">
            <Modal
              open={infoModalObj !== null}
              onClose={() => {
                setInfoModalObj(null)
              }}
              title={null}
              content={(
                infoModalObj
              )}
              hasBottom={false}
            />
            <div className="settings-left">
              {email !== null && <GeneralInfoTab/>}
              <div className="settings-element">
                <span className="settings-element-label">Use Photos in Switcher</span>
                <img onClick={() => {
                  setInfoModalObj(settingsInfo)
                }} src={infoIcon} alt="" className="settings-element-info-icon" />
                <div className="settings-element-right">
                  <Switch
                    onChange={(checked) => {
                      setPhotosInSwitcher(checked)
                    }}
                    checked={photos_in_switcher}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </div>
              <LocationTab/>
              <div className="settings-element">
                <Link to="/users/blocked" className="settings-element-link">Review Blocked users</Link>
              </div>
              <div className="settings-element">
                <span className="settings-element-link" onClick={logout}>Logout</span>
              </div>
              <div className="settings-element" onClick={() => {
                setInfoModalObj(deleteInfo)
              }}>
                <span className="settings-element-link red">Delete Your Account</span>
              </div>
            </div>
            <div className="settings-right">
              <span className="settings-right-text">Update your account information and log out</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Settings;
