import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { apiProfileChangeNote, apiProfileGetNote } from "../../../actions/profileActions";

type NoteModalType = {
  targetProfileId: string,
  onClose: () => void
}

function NoteModal({targetProfileId, onClose}: NoteModalType) {

  const token = useSelector((state: any) => state.common.token);

  const [loading, setLoading] = useState(true)
  const [text, setText] = useState<any>('');

  useEffect(() => {
    loadNote()
  }, [targetProfileId])

  const loadNote = async () => {

    setLoading(true)

    const text = await apiProfileGetNote(token, targetProfileId);

    setText(text)
    setLoading(false)

  }

  const saveNote = () => {

    apiProfileChangeNote(token, targetProfileId, text);
    onClose()

  }

  return (
    <div className="note-modal">
      {loading ? (
        <div className="spinner"/>
      ) : (
       <>
          <textarea
            className="note-modal-textarea"
            value={text}
            onChange={(event) => {
              setText(event.target.value)
            }}
            placeholder={"Enter your note"}
          />
         <div className="note-modal-bottom">
            <button className="note-modal-submit-button" onClick={saveNote}>Save</button>
         </div>
       </>
      )}
    </div>
  )


}

export default NoteModal
