import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { apiUserChangePassword } from '../../../actions/userActions';
import Modal from '../../../components/ui/modal/Modal';
import { userSignOutAction } from '../../../reducers/common';

const initialPasswordData = {
  newPassword: '',
  repeatPassword: ''
}

function GeneralInfoTab({}: any) {
  const email = useSelector((state: any) => state.common.user.email);
  const token = useSelector((state: any) => state.common.token);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [passwordData, setPasswordData] = useState(initialPasswordData);

  const dispatch = useDispatch();
  
  useEffect(() => {
    if (openChangePassword) {
      setPasswordData(initialPasswordData);
    }
  }, [openChangePassword])

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value
    }))
  };

  const onSavePasswordClick = async () => {
    if (passwordData.newPassword !== passwordData.repeatPassword) {
      alert('Error! Passwords are not equal.');
      return;
    }
    try {
      const result = await apiUserChangePassword({
        token,
        password: passwordData.newPassword
      });
      console.log(result);
      if (result.status === 'ok') {
        dispatch(userSignOutAction());
      }
    } catch (error) {
      console.error(error);
    }

  }

  return (
    <>
      <div className="settings-tab">
        <div className={`settings-tab-top ${open  ? 'active' : ''}`} onClick={() => {
          setOpen(!open)
        }}>
          <span className="settings-tab-top-label">Account Details</span>
          <span className="settings-tab-top-icon">{open ? '-' : '+'}</span>
        </div>
        {open && (
          <div className="settings-tab-content">
            <div className="general-info-element">
             <div className="general-info-element-top">
               <span className="general-info-element-label">Email</span>
               <input type="text" value={email} disabled={true} className="general-info-element-input"/>
             </div>
              {/* TODO */}
              {/* <span className="general-info-element-change">Change email</span> */}
            </div>
            <div className="general-info-element">
              <div className="general-info-element-top">
                <span className="general-info-element-label">Password</span>
                <input type="text" value="*********" disabled={true} className="general-info-element-input"/>
              </div>
              <span className="general-info-element-change" onClick={() => setOpenChangePassword(true)}>
                Change password
              </span>
            </div>
            <Modal
              open={openChangePassword}
              onClose={() => setOpenChangePassword(false)}
              title={'Change Password'}
              content={(
                <div className='change-password-modal-container'>
                  <input
                    value={passwordData.newPassword}
                    id='newPassword'
                    type="password"
                    onChange={onChangePassword}
                    placeholder="Enter new password"  
                  />
                  <input
                    value={passwordData.repeatPassword}
                    id='repeatPassword'
                    type="password"
                    onChange={onChangePassword}
                    placeholder="Repeat new password"  
                  /> 
                  <div className='btns-container'>
                    <span className='btn cancel' onClick={() => setOpenChangePassword(false)}>
                      Cancel
                    </span>
                    <span className='btn' onClick={onSavePasswordClick}>Save</span>
                  </div>
                </div>
              )}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default GeneralInfoTab
