import axios from "axios";
import {API_URL} from "../core/links";

export const apiChatsGet = (
  token: string,
  profile_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v2/chats.get?profile_id=${profile_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.chats);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiChatsGetMessages = (
  token: string,
  chat_id: string,
): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/v2/chats.get_messages?chat_id=${chat_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.messages);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiChatSendMessage = (
  token: string,
  chat_id: string,
  profile_id: string,
  text: string
): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + `/chat.send_message`, {
      chat_id,
      profile_id,
      text
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}
