import React, {useState} from 'react';
import OtpInput from "react18-input-otp";
import {apiUserConfirmCheck} from "../actions/userActions";
import {useDispatch} from "react-redux";
import {userSignInAction} from "../reducers/common";

export type ConfirmType = {
  phone: string,
  confirm_id: number
}

type OtpType = {
  confirm: ConfirmType | null,
  onBack: () => void
}

function Otp({confirm, onBack}: OtpType) {

  const dispatch = useDispatch();

  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [submitLoading, setSubmitLoading] = useState(false)

  const submit = async () => {

    setSubmitLoading(true)

    try {

      const response = await apiUserConfirmCheck(Number(confirm?.confirm_id), code)
      console.log('response', response)

      dispatch(userSignInAction({
        token: response.token,
        user: response.user,
        profiles: response.profiles,
      }));



    } catch {
      setSubmitLoading(false)
    }

  }

  if (confirm === null) {
    return <div/>
  }

  return (
    <div className="login-page-content-inner">
      <h1 className="login-page-title">Enter the code</h1>
      <div className="login-page-text">SMS with an activation code has been sent to your phone number {confirm.phone}</div>
      <div className="login-page-otp">
        <OtpInput
          value={code}
          onChange={(code: string) => {
            setCode(code)
          }}
          onSubmit={submit}
          numInputs={4}
          inputStyle={{
            backgroundColor: 'transparent',
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderBottomWidth: 2,
            borderColor: 'rgba(0, 80, 219, 1)',
            marginRight: 20,
            outline: 'none',
            marginTop: 40,
            width: 30,
            textAlign: 'center',
            color: '#ffffff',
            fontSize: 14,
            marginBottom: 20,
            height: 30
          }}
        />
        {error.length > 0 && <span className="login-page-otp-error">{error}</span>}
        <div className="login-page-submit-area">
          <span className="login-page-submit-area-back-link" onClick={() => {
            onBack();
          }}>Back</span>
          <button disabled={submitLoading} className="login-page-submit-button" onClick={submit}>
            Submit
            {submitLoading && <div className="login-page-submit-button-spinner"/>}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Otp;
