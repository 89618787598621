import React from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

type CalendarProps = {
  date: Date | null,
  onChange: (date: Date) => void
}

function CalendarPicker({date, onChange}: CalendarProps) {

  return (
    <DatePicker
      dateFormat="yyyy-MM-dd"
      selected={date}
      onChange={onChange}
      placeholderText="Select date"
    />
  );

}

export default CalendarPicker
