import {capitalizeFirstLetter, checkElementInArray} from "../helpers/helpers";
import moment from "moment";

const CLEAR_PROFILE_DATA = 'CLEAR_PROFILE_DATA';

const SET_CHATS = 'SET_CHATS';
const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
const SET_PENDING = 'SET_PENDING';

const FLEX = 'FLEX'
const FAVOURITE = 'FAVOURITE'
const BLOCK = 'BLOCK'

const SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT'

const CREATE_CHAT = 'CREATE_CHAT'

const SET_CHAT_LAST_MESSAGE = 'SET_CHAT_LAST_MESSAGE'

const CHANGE_CHAT_ID = 'CHANGE_CHAT_ID'

const defaultState = {

  chats: [],
  notifications: [],
  flexes: [],
  favourites: [],
  shares: [],
  blocks: [],

  active_chat: null,

  isChatsPending: true,

};

type ActionProps = {
  type: string,
  payload: any
}

export const profileReducer = (state = defaultState, action: ActionProps) => {
  switch (action.type) {
    case CLEAR_PROFILE_DATA:
      return {
        ...defaultState
      };
    case SET_CHATS:
      return {
        ...state,
        chats: action.payload.chats
      };
    case SET_ACTIVE_CHAT:
      return {
        ...state,
        active_chat: action.payload.active_chat
      };
    case SET_CHAT_LAST_MESSAGE:

      const chats: any = [...state.chats]
      chats.forEach((_chat: any, index: number) => {
        if (_chat.id === action.payload.chat_id) {
          chats[index].last_message = {
            text: action.payload.message
          }
          chats[index].timestamp = moment().unix()
        }
      })

      return {
        ...state,
        chats
      }
    case CHANGE_CHAT_ID:

      const _chats: any = [...state.chats]
      _chats.forEach((_chat: any, index: number) => {
        if (_chat.id === action.payload.old_id) {
          _chats[index].id = action.payload.new_id;
        }
      })

      return {
        ...state,
        _chats
      }
    case CREATE_CHAT: {

      const chat = {
        id: `target${action.payload.profile.id}`,
        src_profile_id: action.payload.src_profile_id,
        target_profile_id: action.payload.target_profile_id,
        profile: action.payload.profile,
        last_message: null,
        timestamp: moment().unix()
      }

      return {
        ...state,
        chats: [
          chat,
          ...state.chats
        ],
        active_chat: chat
      }
    }
    case SET_PROFILE_DATA:
      return {
        ...state,
        flexes: action.payload.flexes,
        favourites: action.payload.favourites,
        blocks: action.payload.blocks,
      };
    case FLEX:
     return {
       ...state,
       flexes: checkElementInArray(state.flexes, action.payload.profile_id)
     }
    case FAVOURITE:
      return {
        ...state,
        favourites: checkElementInArray(state.favourites, action.payload.profile_id)
      }
    case BLOCK:
      return {
        ...state,
        blocks: checkElementInArray(state.blocks, action.payload.profile_id)
      }
    case SET_PENDING:
      const fieldName = `is${capitalizeFirstLetter(action.payload.type)}Pending`
      return {
        ...state,
        [fieldName]: action.payload.value
      };
    default:
      return state;
  }
};

export const setChatsAction = (payload : object) => ({type: SET_CHATS, payload});
export const setPending = (payload : object) => ({type: SET_PENDING, payload});
export const setProfileDataAction = (payload : object) => ({type: SET_PROFILE_DATA, payload});

export const flexAction = (payload : object) => ({type: FLEX, payload});
export const favouriteAction = (payload : object) => ({type: FAVOURITE, payload});
export const blockAction = (payload : object) => ({type: BLOCK, payload});

export const setActiveChatAction = (payload : object) => ({type: SET_ACTIVE_CHAT, payload});

export const createChatAction = (payload: object) => ({type: CREATE_CHAT, payload})
export const setChatLastMessage = (payload: object) => ({type: SET_CHAT_LAST_MESSAGE, payload})

export const clearProfileDataAction = (payload: object) => ({type: CLEAR_PROFILE_DATA, payload})

export const changeChatIdAction = (payload: object) => ({type: CHANGE_CHAT_ID, payload})
