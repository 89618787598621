import React from 'react';
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";

function Loading() {

  return (
    <>
      <Header/>
      <div className="loading-page">
        <div className="lds-ripple">
          <div/>
          <div/>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Loading;
