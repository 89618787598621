import React from 'react';

type LabelTypesTabProps = {
  label: string,
  type: string
}

type LabelTypesProps = {
  tabs: LabelTypesTabProps[],
  current: string,
  onChange: (tab: string) => void
}

function LabelTabs({tabs, current, onChange}: LabelTypesProps) {
  return (
    <div className="login-page-tabs">
      {tabs.map((tab: LabelTypesTabProps, index: number) => {
        return (
         <>
           <span
             className={`login-page-tab ${current === tab.type && 'active'}`}
             onClick={() => {
               onChange(tab.type)
             }}
           >{tab.label}</span>
           {index < tabs.length - 1 && <span className="login-page-tab-line">/</span>}
         </>
        )
      })}
    </div>
  );
}

export default LabelTabs;
