import React from 'react';
import {getCroppedImageUrl} from "../../../helpers/helpers";
// @ts-ignore
import timeago from 'epoch-timeago';

function ChatItem({chat, active, onClick} : any) {

  return (
    <div className={`chat-element ${active && 'active'}`} onClick={() => {
      onClick()
    }}>
      <div className="chat-element-left">
        <img src={getCroppedImageUrl(chat.profile.avatar, 45, 45)} className="chat-element-avatar" alt=""/>
      </div>
      <div className="chat-element-right">
        <div className="chat-element-right-top">
          <span className="chat-element-name">{chat.profile.name}</span>
          <div className="chat-element-timestamp">{timeago(chat.timestamp * 1000)}</div>
        </div>
        <div className="chat-element-right-bottom">
          {chat.last_message !== null && <span className="chat-element-last-message">{chat.last_message.text}</span>}
        </div>
      </div>
    </div>
  );
}

export default ChatItem;
