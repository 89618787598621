import React from 'react';

type CheckboxType = {
  label: string,
  checked: boolean,
  onChange: (checked: boolean) => void,
  icon?: any
}

function Checkbox({label, checked, onChange, icon}: CheckboxType) {
  return (
    <label className="edit-profile-checkbox">
      <input type="checkbox" checked={checked} onChange={(event) => {
        onChange(event.target.checked)
      }}/>
      <div className="edit-profile-checkbox-checkmark">
        <div className="edit-profile-checkbox-checkmark-inner"/>
      </div>
      {icon && icon}
      <span className="edit-profile-checkbox-label">{label}</span>
    </label>
  );
}

export default Checkbox
