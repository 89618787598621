import React from 'react';
import Header from "../../layout/components/header/Header";
import {Outlet} from "react-router-dom";
import Footer from "../../layout/components/footer/Footer";

const promo = require('../../assets/promo.jpg');

function PageNotFound() {

  return (
    <>
      <Header/>
      <div className="login-page">
        <div className="container with-flex-row">
          <div className="login-page-content">
            <div className="login-page-content-inner">
              <h1 className="login-page-title">Page not found</h1>
              <div className="login-page-text">Sorry, the page you're looking for cannot be found</div>
            </div>
          </div>
          <div className="login-page-image" style={{backgroundImage: `url(${promo})`}}/>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default PageNotFound;
