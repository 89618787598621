import React from 'react';
import Switcher from "../switcher/Switcher";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

const menuMessages = require('../../../assets/menu/messages.png');
const menuNotifications = require('../../../assets/menu/notifications.png');
const menuProfiles = require('../../../assets/menu/profiles.png');
const menuSettings = require('../../../assets/menu/settings.png');
const menuStats = require('../../../assets/menu/stats.png');
const menuSupport = require('../../../assets/menu/support.png');
const menuSearch = require('../../../assets/menu/search.png');
const menuVisibility = require('../../../assets/menu/visibility.png');

const menuLinks = [
  {
    icon: menuProfiles,
    label: 'My Profiles',
    link: '/profiles',
    isProfileActivity: false
  },
  {
    icon: menuNotifications,
    label: 'Notifications',
    link: '/notifications',
    isProfileActivity: true,
  },
  {
    icon: menuMessages,
    label: 'Messages',
    link: '/messages',
    isProfileActivity: true,
  },
  {
    icon: menuStats,
    label: 'My Stats',
    link: '/stats',
    isProfileActivity: true
  },
  {
    icon: menuVisibility,
    label: 'Visibility',
    link: '/visibility',
    isProfileActivity: false
  },
  {
    icon: menuSettings,
    label: 'Settings',
    link: '/settings',
    isProfileActivity: false
  },
  {
    icon: menuSearch,
    label: 'Search',
    link: '/search',
    isProfileActivity: true
  },
  {
    icon: menuSupport,
    label: 'Support',
    link: '/support'
  }
]

function Menu({className, onClick}: any) {

  const profiles = useSelector((state: any) => state.common.profiles);
  const type = useSelector((state: any) => state.common.type);
  const badges = useSelector((state: any) => state.common.badges);

  const profile = profiles.find((_profile: any) => _profile.type)

  const checkBadge = (link: any) => {

    if (badges[type] === undefined) {
      return <div/>
    }

    if (link.link === '/notifications') {

      const count = badges[type].notifications
      if (count > 0) {
        return <div className="menu-element-badge">{count}</div>
      }

    }

    if (link.link === '/messages') {

      const count = badges[type].messages
      if (count > 0) {
        return <div className="menu-element-badge">{count}</div>
      }

    }

  }

  return (
    <div className={className}>
      <Switcher/>
      <div className="menu">
        {menuLinks.map((link) => {

          if (link.isProfileActivity && profile === undefined) {
            return <div/>
          }

          return (
            <NavLink to={link.link} className="menu-element" onClick={() => {
              if (className === 'mobile-menu') {
                onClick()
              }
            }}>
              <img src={link.icon} alt="" className="menu-element-icon"/>
              <span className="menu-element-label">{link.label}</span>
              {checkBadge(link)}
            </NavLink>
          )
        })}
      </div>
    </div>
  );
}

export default Menu;
