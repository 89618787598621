import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { apiUserChangeSafeForWork } from "../../../actions/userActions";
import { MapComponentWithCircle } from "../../../components/ui/MapComponent";
import { TimeInput } from "../../../components/ui/TimeInput";
import { setSafeForWorkAction } from "../../../reducers/common";

export type SafeForWorkType = {
  enabled: boolean,
  lat: string | null,
  lng: string | null,
  begin: string | null,
  end: string | null
}

function SafeForWork() {

  const dispatch = useDispatch()

  const token = useSelector((state: any) => state.common.token);

  const safe_for_work = useSelector((state: any) => state.common.user.safe_for_work);

  const lat = useSelector((state: any) => state.common.user.lat);
  const lng = useSelector((state: any) => state.common.user.lng);

  let centerLat = lat !== null ? parseFloat(lat) : 34.09001
  let centerLng = lat !== null ? parseFloat(lng) : -118.36174

  let mapLat = safe_for_work.lat !== null ? parseFloat(safe_for_work.lat) : lat
  if (mapLat === null) {
    mapLat = 34.09001
  }
  let mapLng = safe_for_work.lng !== null ? parseFloat(safe_for_work.lng) : lng
  if (mapLng === null) {
    mapLng = -118.36174
  }

  const updateSafeForWorkData = (safe_for_work: SafeForWorkType) => {

    apiUserChangeSafeForWork(
      token,
      safe_for_work,
    )
    dispatch(setSafeForWorkAction({
      safe_for_work
    }))


  }

  return (
    <div>
      <div className="visibility-tab-content-element">
        <span className="visibility-tab-content-element-label">On/Off</span>
        <div className="visibility-tab-content-element-right">
          <Switch
            onChange={(checked) => {
              updateSafeForWorkData({
                ...safe_for_work,
                enabled: checked
              })
            }}
            checked={safe_for_work.enabled}
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </div>
      </div>
      <div className="visibility-tab-content-element">
        <span className="visibility-tab-content-element-label">Begin</span>
        <div className="visibility-tab-content-element-right">
          <TimeInput
            className="visibility-tab-content-element-input"
            onBlur={(event: any) => {
              updateSafeForWorkData({
                ...safe_for_work,
                begin: event.target.value
              })
            }}
            value={safe_for_work.begin}
          />
        </div>

      </div>
      <div className="visibility-tab-content-element">
        <span className="visibility-tab-content-element-label">End</span>
        <div className="visibility-tab-content-element-right">
          <TimeInput
            className="visibility-tab-content-element-input"
            onBlur={(event: any) => {
              updateSafeForWorkData({
                ...safe_for_work,
                end: event.target.value
              })
            }}
            value={safe_for_work.end}
          />
        </div>
      </div>
      <MapComponentWithCircle
        containerElement={<div style={{ height: `300px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        // @ts-ignore
        onClick={(event) => {
          const lat = event.latLng?.lat();
          const lng = event.latLng?.lng();
          updateSafeForWorkData({
            ...safe_for_work,
            lat,
            lng
          })
        }}
        coords={{ lat: mapLat, lng: mapLng}}
        centerCoords={{ lat: centerLat, lng: centerLng}}
      />
    </div>
  );
}

export default SafeForWork;
