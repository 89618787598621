import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getCroppedImageUrl} from "../../../helpers/helpers";
import Switch from "react-switch";
import {apiUserChangeShare, apiUserGetShareInfo} from "../../../actions/userActions";

type UserPagePhotosType = {
  targetUserId: string,
  myProfile: any
}

function ShareModal({targetUserId, myProfile}: UserPagePhotosType) {

  const token = useSelector((state: any) => state.common.token);
  const profiles = useSelector((state: any) => state.common.profiles);

  const [loading, setLoading] = useState(true)
  const [info, setInfo] = useState<any>(null)

  useEffect(() => {
    loadShareInfo()
  }, [targetUserId])

  const loadShareInfo = async () => {

    setLoading(true)

    const info = await apiUserGetShareInfo(token, targetUserId);
    setInfo(info);

    setLoading(false)

  }

  const changeAllow = (type: string, checked: boolean) => {

    setInfo({
      ...info,
      [type]: +checked
    })
    apiUserChangeShare(
      token,
      targetUserId,
      type,
      +checked
    )

  }

  const checkSelector = (type: string) => {

    if (type !== myProfile.type) {

      const profile = profiles.find((profile: any) => profile.type === type)
      if (profile !== undefined) {

        let checked = true;
        if (info !== null) {
          checked = info[type];
        }

        return (
          <div className="share-modal-element">
            <img src={getCroppedImageUrl(profile.avatar, 45,45)} alt="" className="share-modal-element-image"/>
            <span className="share-modal-element-type">{type}</span>
            <div className="share-modal-element-right">
              <Switch
                onChange={(checked) => {
                  changeAllow(type, checked)
                }}
                checked={checked}
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
          </div>
        )

      }
    }

  }

  return (
    <div className="share-modal">
      {loading ? (
        <div className="spinner"/>
      ) : (
        <>
          <span className="share-modal-text">To give or block, tap each of the profile toggles</span>
          <div className="share-modal-elements">
            {checkSelector('friend')}
            {checkSelector('flirt')}
            {checkSelector('fun')}
          </div>
        </>
      )}
    </div>
  )


}

export default ShareModal
