import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCroppedImageUrl} from "../../../helpers/helpers";
import {typeIcons} from "../../../core/icons";
import {useNavigate} from "react-router-dom";
import {changeTypeAction} from "../../../reducers/common";

const friendText = require('../../../assets/profile/friend-text.png');
const flirtText = require('../../../assets/profile/flirt-text.png');
const funText = require('../../../assets/profile/fun-text.png');

const emptyImage = require('../../../assets/profile/empty.png');

const plusIcon = require('../../../assets/profile/plus.png');
const editIcon = require('../../../assets/profile/edit.png');

const onlineGreen = require('../../../assets/online/green.png');
const onlineRed = require('../../../assets/online/red.png');

const messagesIcon = require('../../../assets/profile/messages.png');
const notificationsIcon = require('../../../assets/profile/notifications.png');
const gridIcon = require('../../../assets/profile/grid.png');

type ProfileType = {
  type: string,
  profile: any
}

function Profile({type, profile}: ProfileType) {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const currentType = useSelector((state: any) => state.common.type);
  const badges = useSelector((state: any) => state.common.badges);

  const changeType = () => {

    if (profile !== null) {
      if (profile.type !== currentType) {
        dispatch(changeTypeAction({
          type
        }))
      }
    }

  }

  const navigateTo = (to: string) => {

    dispatch(changeTypeAction({
      type
    }))
    navigate(to)

  }

  const editProfile = () => {
    dispatch(changeTypeAction({
      type
    }))
    navigate(`/profile/${type}`)
  }

  return (
    <div className="profile" onClick={profile === null ? editProfile : () => {}}>
      <div className="profile-circle">
        {profile === null ? (
          <>
            <img src={emptyImage} alt="" className="profile-circle-image"/>
            <img src={plusIcon} alt="" className="profile-circle-plus"/>
          </>
        ) : (
          <>
            <div className={`profile-circle-icon ${type}`}>
              <img src={typeIcons[type]} alt="" className="profile-circle-icon-image"/>
            </div>
            <img src={getCroppedImageUrl(profile.avatar, 120, 120)} alt="" className="profile-circle-image"/>
            <img src={editIcon} alt="" className="profile-circle-plus"/>
          </>
        )}
      </div>
      <div className="profile-left"/>
      <div className={`profile-inner ${type}`}>
        <div className="profile-inner-left">
          {profile === null ? (
            <span className="profile-name">Create profile</span>
          ) : (
            <div className="profile-middle">
              <span className="profile-name">{profile.name}</span>
              <div className="profile-icons">
                <div className="profile-icon" onClick={() => {
                  navigateTo('/users')
                }}>
                  <img src={gridIcon} alt="" className="profile-icon-image"/>
                </div>
                <div className="profile-icon" onClick={() => {
                  navigateTo('/notifications')
                }}>
                  {badges[type].notifications > 0 && <span className="profile-icon-badge">{badges[type].notifications}</span>}
                  <img src={notificationsIcon} alt="" className="profile-icon-image"/>
                </div>
                <div className="profile-icon" onClick={() => {
                  navigateTo('/messages')
                }}>
                  {badges[type].messages > 0 && <span className="profile-icon-badge">{badges[type].messages}</span>}
                  <img src={messagesIcon} alt="" className="profile-icon-image"/>
                </div>
              </div>
              <span className="profile-edit-link" onClick={editProfile}>Edit Profile</span>
            </div>
          )}
        </div>
        <div className="profile-inner-right" onClick={changeType}>
          {profile !== null && (
            <>
              {
                profile.type === currentType
                  ? <img src={onlineGreen} alt="" className="profile-active-icon"/>
                  : <img src={onlineRed} alt="" className="profile-active-icon"/>
              }
            </>
          )}
          {type === 'friend' && <img src={friendText} alt="" className="profile-text-image"/>}
          {type === 'flirt' && <img src={flirtText} alt="" className="profile-text-image"/>}
          {type === 'fun' && <img src={funText} alt="" className="profile-text-image"/>}
        </div>
      </div>
    </div>
  );
}

export default Profile;
function userChangeTypeAction(arg0: { type: string; }): any {
    throw new Error('Function not implemented.');
}

