import React, {useEffect, useState} from 'react';
import {
  Outlet, useNavigate
} from "react-router-dom";
import {useSelector} from "react-redux";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Menu from "./components/menu/Menu";
import {SITE_URL} from "../core/links";

function NoAuth() {

  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: any) => state.common.isLoggedIn);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/profiles')
    }
  }, [isLoggedIn])

  return (
    <>
      {mobileMenuOpen && (
        <>
          <div className="mobile-menu-fade" onClick={() => {
            setMobileMenuOpen(false)
          }}/>
          <div className="mobile-menu">
            <div className="menu">
              <a href={SITE_URL} className="menu-element">
                <span style={{marginLeft: 0}} className="menu-element-label">About us</span>
              </a>
              <a href={SITE_URL} className="menu-element">
                <span style={{marginLeft: 0}} className="menu-element-label">Blog</span>
              </a>
              <a href={SITE_URL} className="menu-element">
                <span style={{marginLeft: 0}} className="menu-element-label">Contact Us</span>
              </a>
            </div>
          </div>
        </>
      )}
      <Header onMobileMenuOpen={() => {
        setMobileMenuOpen(!mobileMenuOpen)
      }}/>
      <Outlet/>
      <Footer/>
    </>
  );
}

export default NoAuth;
