import React, {useState} from 'react';
import {Link} from "react-router-dom";
import LabelTabs from "../../../components/ui/LabelTabs";
import Field from "../../../components/ui/Field";
import {ConfirmType} from "../../../components/Otp";
import {apiUserConfirmCreate, apiUserLogin, apiUserSendResetEmail} from "../../../actions/userActions";
import {userSignInAction} from "../../../reducers/common";
import {useDispatch} from "react-redux";
import Modal from '../../../components/ui/modal/Modal';

type LoginFieldsType = {
  onSetConfirm: (confirm: ConfirmType | null) => void
  onSetActivationEmail: (email: string) => void
}

function LoginFields({onSetConfirm, onSetActivationEmail}: LoginFieldsType) {

  const dispatch = useDispatch()

  const [type, setType] = useState('email')

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const [phone, setPhone] = useState('+')
  const [phoneError, setPhoneError]  = useState('')

  const [accept, setAccept] = useState(false)

  const [submitLoading, setSubmitLoading] = useState(false)

  const [resetEmail, setResetEmail] = useState('');
  const [openResetPwdModal, setOpenResetPwdModal] = useState(false);

  const submit = async () => {

    let error = false

    if (type === 'email') {
      if (email.length < 3) {
        setEmailError('Invalid email')
        error = true
      }
      if (password.length < 6) {
        setPassword('Invalid password')
        error = true
      }
    }

    if (type === 'phone') {
      if (phone.length < 3) {
        setPhoneError('Invalid phone number')
        error = true
      }
    }

    if (error) {
      return
    }

    setSubmitLoading(true)

    try {

      if (type === 'phone') {
        const response = await apiUserConfirmCreate(phone);
        if (response.status !== 'ok') {
          setPhoneError(response.error)
          setSubmitLoading(false)
          return
        }
        onSetConfirm({
          confirm_id: response.confirm_id,
          phone
        })
        setSubmitLoading(false)
      }

      if (type === 'email') {
        const response = await apiUserLogin(email, password);
        console.log(response)
        if (!response.status) {
          if (response.error === 'email_not_found') {
            setEmailError('Account with this email not found')
          }
          if (response.error === 'activation') {
            onSetActivationEmail(email)
            return
          }
          if (response.error === 'incorrect_password') {
            setPasswordError('Invalid password')
          }
          setSubmitLoading(false)
          return
        }

        dispatch(userSignInAction({
          token: response.token,
          user: response.user,
          profiles: response.profiles,
        }));


      }


    } catch {
      // need error exception
      setSubmitLoading(false)
    }


  }

  const onResetPasswordClick = async () => {
    if (!resetEmail) {
      return;
    }
    try {
      const result = await apiUserSendResetEmail(resetEmail);
      alert('Guide to reset you password has been sent to the specified email.');
      setOpenResetPwdModal(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="login-page-content-inner">
      <Modal
        open={openResetPwdModal}
        onClose={() => setOpenResetPwdModal(false)}
        content={(
          <div className='reset-password-modal'>
            <input
              type="text"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              className='login-page-fields-input'
              placeholder='Enter your email'
            />
            <span onClick={onResetPasswordClick}>
              Reset
            </span>
          </div>
        )}
        title='Reset Password'      
      />
      <h1 className="login-page-title">Login</h1>
      <div className="login-page-fields">
        <div className="login-page-fields-area">
          <LabelTabs
            tabs={[
              {
                label: 'Email Address',
                type: 'email'
              },
              {
                label: 'Phone',
                type: 'phone'
              }
            ]}
            current={type}
            onChange={(type) => {
              setType(type)
              setPassword('')
            }}
          />
          {type === 'email' && (
            <>
              <Field
                type={'email'}
                className={'login-page-fields'}
                value={email}
                error={emailError}
                onChange={(value) => {
                  setEmail(value);
                  setEmailError('');
                }}
                id={'loginPageEmail'}
              />
              <Field
                label={'Password'}
                type={'password'}
                className={'login-page-fields'}
                value={password}
                error={passwordError}
                onChange={(value) => {
                  setPassword(value);
                  setPasswordError('')
                }}
                id={'loginPagePassword'}
              />
              <span className='forgot-pass' onClick={() => setOpenResetPwdModal(true)}>
                Forgot password?
              </span>
            </>
          )}
          {type === 'phone' && (
            <Field
              type={'phone'}
              className={'login-page-fields'}
              value={phone}
              error={phoneError}
              onChange={(value) => {
                setPhone(value);
                setPhoneError('')
              }}
              id={'loginPagePhone'}
            />
          )}
        </div>
        <div className="login-page-submit-area">
          <label className="login-page-checkbox">
            <input type="checkbox" checked={accept} onChange={(event) => {
              setAccept(event.target.checked)
            }}/>
            <div className="login-page-checkbox-checkmark">
              <div className="login-page-checkbox-checkmark-inner"/>
            </div>
            <span className="login-page-checkbox-label">Keep me logged in</span>
          </label>
          <button disabled={submitLoading} className="login-page-submit-button" onClick={submit}>
            {type === 'email' && "Login"}
            {type === 'phone' && "Send code"}
            {submitLoading && <div className="login-page-submit-button-spinner"/>}
          </button>
        </div>
        <div className="login-page-bottom">
          <span className="login-page-bottom-link">Don’t have an account? <Link to="/register">SIGN UP</Link></span>
        </div>
      </div>
    </div>
  );
}

export default LoginFields;
