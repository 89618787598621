import React from 'react';
import {getCroppedImageUrl} from "../../../helpers/helpers";
// @ts-ignore
import timeago from 'epoch-timeago';

function Message({message, profile_id} : any) {

  console.log(message.text, message.profile_id, profile_id)

  if (message.text === null) {
    return <div/>
  }

  if (message.profile_id !== profile_id) {
    return (
      <div className="chat-message-2">
        <div className="chat-message-2-inner">
          <img src={getCroppedImageUrl(message.profile.avatar, 45,45)} alt="" className="chat-message-2-avatar"/>
          <div className="chat-message-2-bubble">
            <span className="chat-message-2-text">{message.text}</span>
            <div className="chat-message-2-date">{timeago(message.timestamp * 1000)}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="chat-message">
      <div className="chat-message-inner">
        <div className="chat-message-bubble">
          <span className="chat-message-text">{message.text}</span>
          <div className="chat-message-date">{timeago(message.timestamp * 1000)}</div>
        </div>
        <img src={getCroppedImageUrl(message.profile.avatar, 45,45)} alt="" className="chat-message-avatar"/>
      </div>
    </div>
  );
}

export default Message;
