import React, {useRef, useState} from 'react';
import {apiProfilePhotoCreate, apiProfilePhotoDelete} from "../../../actions/profileActions";
import {useSelector} from "react-redux";
import {getCroppedImageUrl} from "../../../helpers/helpers";

const placeholderImage = require('../../../assets/placeholder.png');
const iconCircleCloseImage = require('../../../assets/icon-circle-close.png');

export type PhotoType = {
  path: string,
  id: string
  type: 'private' | 'public'
  adult: 0 | 1
  status: 0 | 1
}

type PhotosProps = {
  photos: PhotoType[]
  profile_id: string,
  onChange: (photos: PhotoType[]) => void
  type: 'private' | 'public'
}

function Photos({photos, onChange, profile_id, type}: PhotosProps) {

  const [loading, setLoading] = useState(false)

  const token = useSelector((state: any) => state.common.token)

  const inputFile = useRef<any>(null)

  const uploadPhoto = () => {
    inputFile.current.click();
  }

  const onFileChange = async (event: any) => {

    if (event.target.files.length > 0) {

      setLoading(true)

      const response = await apiProfilePhotoCreate(
        token,
        profile_id,
        event.target.files[0],
        type
      );
      if (response.error) {
        alert(response.error);
      } else if (response.photos) {
        onChange(response.photos)
      }

      setLoading(false)


    }

  }

  const deletePhoto = async (photo_id: string) => {

    setLoading(true)

    const response = await apiProfilePhotoDelete(token, profile_id, photo_id)

    onChange(response.photos)

    setLoading(false)

  }

  return (
    <div className="edit-profile-private-photo">
      <span className="edit-profile-private-photo-title">
        {type === 'private' && 'Private Photos'}
        {type === 'public' && 'Public Photos'}
      </span>
      <span className="edit-profile-private-photo-sub-title">
        {type === 'private' && 'Your photos are private. You can select to whom you like to see them privately.'}
        {type === 'public' && 'Your public photos can be seen by anyone. No nudes in here.'}
      </span>
      <div className="edit-profile-private-photo-elements">
        <input
          accept={"image/jpeg, image/png, image/gif"}
          type="file"
          ref={inputFile}
          style={{display: "none"}}
          onChange={onFileChange}
        />
        {loading ? <div className="spinner"/> : (
          <>
            <Photo photo={photos.length > 0 ? photos[0] : null} onUpload={uploadPhoto} onDelete={deletePhoto}/>
            <Photo photo={photos.length > 1 ? photos[1] : null} onUpload={uploadPhoto} onDelete={deletePhoto}/>
            <Photo photo={photos.length > 2 ? photos[2] : null} onUpload={uploadPhoto} onDelete={deletePhoto}/>
            <Photo photo={photos.length > 3 ? photos[3] : null} onUpload={uploadPhoto} onDelete={deletePhoto}/>
          </>
        )}
      </div>
    </div>
  );
}

function Photo({photo, onUpload, onDelete}: {
  photo: null | PhotoType,
  onUpload: () => void
  onDelete: (photo_id: string) => void
}) {

  if (photo !== null) {
    return (
      <div className="edit-profile-private-photo-element">
        <div className="edit-profile-private-photo-element-close" onClick={() => {
          onDelete(photo.id)
        }}>
          <img className="edit-profile-private-photo-element-close-icon" src={iconCircleCloseImage} alt=""/>
        </div>
        <img
          className="edit-profile-private-photo-element-image"
          src={getCroppedImageUrl(photo.path, 150, 150)}
          alt=""
        />
      </div>
    )
  }

  return (
    <div className="edit-profile-private-photo-element">
      <img
        onClick={onUpload}
        className="edit-profile-private-photo-element-image"
        src={placeholderImage}
        alt=""
      />
    </div>
  )

}

export default Photos
