import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {flirtIcon, friendIcon, funIcon} from "../../../core/icons";
import {changeTypeAction} from "../../../reducers/common";
import {useLocation, useNavigate} from "react-router-dom";
import {getCroppedImageUrl} from "../../../helpers/helpers";

function Switcher() {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const profiles = useSelector((state: any) => state.common.profiles);
  const type = useSelector((state: any) => state.common.type);
  const photos_in_switcher = useSelector((state: any) => state.common.user.photos_in_switcher);

  const profile_friend = profiles.find((profile: any) => profile.type === 'friend');
  const profile_flirt = profiles.find((profile: any) => profile.type === 'flirt');
  const profile_fun = profiles.find((profile: any) => profile.type === 'fun');

  const changeType = (type: string) => {

    const profile = profiles.find((_profile: any) => _profile.type === type)
    if (profile === undefined) {
      navigate(`/profile/${type}`)
      return
    }

    dispatch(changeTypeAction({
      type
    }))
    navigate('/profiles')

  }

  return (
    <div className="icons">
      <div className={`icon friend ${type === 'friend' && 'active'}`} onClick={() => {
        changeType('friend')
      }}>
        {photos_in_switcher && profile_friend !== undefined ? <img src={getCroppedImageUrl(profile_friend.avatar, 60, 60)} alt=""/> : <img src={friendIcon} alt=""/>}
        <span className="icon-label">Friend</span>
      </div>
      <div className={`icon flirt ${type === 'flirt' && 'active'}`} onClick={() => {
        changeType('flirt')
      }}>
        {photos_in_switcher && profile_flirt !== undefined ? <img src={getCroppedImageUrl(profile_flirt.avatar, 60, 60)} alt=""/> : <img src={flirtIcon} alt=""/>}
        <span className="icon-label">Flirt</span>
      </div>
      <div className={`icon fun ${type === 'fun' && 'active'}`} onClick={() => {
        changeType('fun')
      }}>
        {photos_in_switcher && profile_fun !== undefined ? <img src={getCroppedImageUrl(profile_fun.avatar, 60, 60)} alt=""/> : <img src={funIcon} alt=""/>}
        <span className="icon-label">Fun</span>
      </div>
    </div>
  );
}

export default Switcher;
