import React, {useState} from 'react';

type SearchTagsProps = {
  tags: [],
  onChange: any
}

function SearchTags({tags, onChange}: SearchTagsProps) {

  const [open, setOpen] = useState(false)

  return (
    <div className="search-tab">
      <div className={`search-tab-top ${open  ? 'active' : ''}`} onClick={() => {
        setOpen(!open)
      }}>
        <span className="search-tab-top-label">Search Hashtags</span>
        <span className="search-tab-top-icon">{open ? '-' : '+'}</span>
      </div>
      {open && (
        <div className="search-tab-content">
          <div className="search-tags">

          </div>
        </div>
      )}
    </div>
  );
}

export default SearchTags;
