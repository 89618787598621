import React from 'react';
import './Profiles.css';
import ProfileEmpty from "./components/ProfileEmpty";
import {useDispatch, useSelector} from "react-redux";
import Profile from "./components/Profile";
import {Link} from "react-router-dom";
import {userSignOutAction} from "../../reducers/common";

function Profiles() {

  const dispatch = useDispatch();

  const profiles = useSelector((state: any) => state.common.profiles);
  const lat = useSelector((state: any) => state.common.user.lat);
  const lng = useSelector((state: any) => state.common.user.lng);

  const friend_profile = profiles.find((profile: any) => {return profile.type === 'friend'})
  const flirt_profile = profiles.find((profile: any) => {return profile.type === 'flirt'})
  const fun_profile = profiles.find((profile: any) => {return profile.type === 'fun'})

  const logout = () => {
    dispatch(userSignOutAction());
  }

  return (
    <div className="profiles-page profiles-page-sub">
      <div className="container with-flex-row">
        {lat === null || lng === null ? (
          <div className="profiles-page-inner">
            <h1 className="profiles-page-title">Please setup your location</h1>
            <span className="profiles-page-text">To view the community and start connecting, you need setup your current location. Musl app wants to use location data to show and calculate distance between other users</span>
            <Link to={'/settings?section=location'} className="profile-page-settings-link">Settings</Link>
            <span className="profiles-page-logout-link" onClick={logout}>Logout</span>
          </div>
        ) : (
          <div className="profiles-page-inner">
            {profiles.length === 0  ? (
              <>
                <h1 className="profiles-page-title">Getting started</h1>
                <span className="profiles-page-text">To view the community and start connecting, all you need to do is select a profile, create a profile name and upload a photo. Of course the more information you give about yourself the better experience you will have. Okay let's get going.</span>
                <div className="profiles-empty">
                  <ProfileEmpty type='friend'/>
                  <ProfileEmpty type='flirt'/>
                  <ProfileEmpty type='fun'/>
                </div>
              </>
            ) : (
              <>
                <h1 className="profiles-page-title">My Profiles</h1>
                <div className="profiles">
                  <Profile type='friend' profile={friend_profile ? friend_profile : null}/>
                  <Profile type='flirt' profile={flirt_profile ? flirt_profile: null}/>
                  <Profile type='fun' profile={fun_profile ? fun_profile: null}/>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Profiles;
