import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCroppedImageUrl} from "../../../helpers/helpers";
import moment from "moment";
import Message from "./Message";
import {changeChatIdAction, setChatLastMessage} from "../../../reducers/profile";
import {apiChatSendMessage, apiChatsGetMessages} from "../../../actions/chatActions";

const onlineGreen = require('../../../assets/online/green.png');
const onlineLurking = require('../../../assets/online/lurking.png');
const onlineRed = require('../../../assets/online/red.png');

const sendIcon = require('../../../assets/chat/send.png');

function ChatWindow({chat} : any) {

  const messagesEndRef = useRef(null);

  const dispatch = useDispatch()

  const token = useSelector((state: any) => state.common.token);
  const type = useSelector((state: any) => state.common.type);
  const profiles = useSelector((state: any) => state.common.profiles);

  const [text, setText] = useState('')

  const myProfile = profiles.find((profile: any) => {return profile.type === type})

  const [messages, setMessages] = useState<any>([])

  useEffect(() => {

    if (chat.id.toString().indexOf('target') === -1) {
      loadMessages()
    } else {
      setMessages([])
    }

  }, [chat])

  const loadMessages = async () => {

    setMessages([])
    const messages = await apiChatsGetMessages(token, chat.id)
    setMessages(messages)
    scrollToBottom()

  }

  const scrollToBottom = () => {
    setTimeout(() => {
      // @ts-ignore
      messagesEndRef.current?.scrollIntoView()
    }, 1)
  }

  const send = (event: any) => {

    event.preventDefault()

    if (text.trim().length > 0) {

      apiChatSendMessage(token, chat.id, myProfile.id, text).then((response: any) => {
        if (chat.id.toString().indexOf('target') !== -1) {
          dispatch(changeChatIdAction({
            new_id: response.chat_id,
            old_id: chat.id
          }))
        }
      })

      setMessages(
        [
          ...messages,
          {
            profile_id: myProfile.id,
            profile: {
              id: myProfile.id,
              avatar: myProfile.avatar,
              name: myProfile.name,
            },
            text: text,
            timestamp: moment().unix()
          }
        ]
      )
      dispatch(setChatLastMessage({
        chat_id: chat.id,
        message: text
      }))
      setText('')
      scrollToBottom()
    }

  }

  const getOnline = () => {

    if (chat.profile.active === 0) {
      return (
        <>
          <img src={onlineRed} className="chat-window-user-circle"/>
          <span className="chat-window-user-status">No active</span>
        </>
      )
    }

    if (chat.profile.online === 1) {
      return (
        <>
          <img src={onlineGreen} className="chat-window-user-circle"/>
          <span className="chat-window-user-status">Online</span>
        </>
      )
    }

    return (
      <>
        <img src={onlineLurking} className="chat-window-user-circle"/>
        <span className="chat-window-user-status">Offline</span>
      </>
    )

  }

  return (
    <div className="chat-window">
      <div className="chat-window-top">
        <div className="chat-window-user">
          <div className="chat-window-user-left">
            <img src={getCroppedImageUrl(chat.profile.avatar, 45, 45)} className="chat-window-user-avatar" alt=""/>
          </div>
          <div className="chat-window-user-right">
            <span className="chat-window-user-name">{chat.profile.name}</span>
           <div className="chat-window-user-bottom">
             {getOnline()}
           </div>
          </div>
        </div>
      </div>
      <div className="chat-window-messages">
       <div className="chat-window-messages-wrapper">
         {messages.map((message: any) => {
           return <Message message={message} profile_id={myProfile.id}/>
         })}
       </div>
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={send} className="chat-window-input-area">
        <input value={text} onChange={(event: any) => {
          setText(event.target.value)
        }} className="chat-window-input-area-field" placeholder={"Type your message ..."}/>
        <button className="chat-window-input-area-submit">
          <img src={sendIcon} className="chat-window-input-area-submit-icon"/>
        </button>
      </form>
    </div>
  );
}

export default ChatWindow;
