import {useState} from "react";
import InputMask from "react-input-mask";

export function TimeInput({className, onBlur, value}: any) {
  const [time, setTime] = useState(value)
  const startsWithTwo = time[0] === '2'

  const handleInput = (event: any) => setTime(event.target.value)

  const mask = [
    /[0-2]/,
    startsWithTwo ? /[0-3]/ : /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/
  ]
  return (
    <InputMask
      className={className}
      mask={mask}
      onChange={handleInput}
      onBlur={onBlur}
      value={time}
    />
  )
}
