export const friendIcon = require('../assets/icons/friend-icon.png');
export const flirtIcon = require('../assets/icons/flirt-icon.png');
export const funIcon = require('../assets/icons/fun-icon.png');

export const typeIcons: any = {
  friend: friendIcon,
  flirt: flirtIcon,
  fun: funIcon
}

export const twoIcon = require('../assets/profile/icons/2.png')

export const safetyPracticeIcons: any = {
  Condoms: require('../assets/profile/icons/safety/condoms.png'),
  Prep: require('../assets/profile/icons/safety/prep.png'),
  Bareback: require('../assets/profile/icons/safety/bareback.png'),
  'Treatment as Prevention': require('../assets/profile/icons/safety/tap.png'),
  'Needs Discussion': require('../assets/profile/icons/safety/needs.png'),
};

export const roleIcons: any = {
  Top: require('../assets/profile/icons/role/icon-top.png'),
  'Top/Versatile': require('../assets/profile/icons/role/icon-top-versatile.png'),
  Versatile: require('../assets/profile/icons/role/icon-versatile.png'),
  'Bottom/Versatile': require('../assets/profile/icons/role/icon-bottom-versatile.png'),
  Bottom: require('../assets/profile/icons/role/icon-bottom.png'),
  'Oral/JO Only': require('../assets/profile/icons/role/icon-oral.png'),
  'Oral/JO': require('../assets/profile/icons/role/icon-oral.png'),
  Side: require('../assets/profile/icons/role/icon-side.png'),
  Sides: require('../assets/profile/icons/role/icon-side.png'),
}

export const hankiesIcons: any = {
  Bondage: require('../assets/profile/icons/hankies/bondage.png'),
  'S&M': require('../assets/profile/icons/hankies/sandm.png'),
  'Dad/Son': require('../assets/profile/icons/hankies/dadson.png'),
  Anything: require('../assets/profile/icons/hankies/anything.png'),
  Watersports: require('../assets/profile/icons/hankies/watersports.png'),
  Anal: require('../assets/profile/icons/hankies/anal.png'),
  Oral: require('../assets/profile/icons/hankies/oral.png'),
  'Safe Sex': require('../assets/profile/icons/hankies/safesex.png'),
  Fisting: require('../assets/profile/icons/hankies/xxx.png'),
  glowIcon: require('../assets/profile/icons/hankies/glow.png'),
}

export const hopingForIcons: any = {
  'Just dating': require('../assets/profile/icons/relationship/dating.png'),
  'A great date or two': require('../assets/profile/icons/relationship/dating.png'),
  'Long-term relationship': require('../assets/profile/icons/relationship/dating.png'),
  Marriage: require('../assets/profile/icons/relationship/dating.png'),
}

export const visitedIcons: any = {
  'friend': require('../assets/profile/icons/visited/friend-triangle.png'),
  'flirt': require('../assets/profile/icons/visited/flirt-triangle.png'),
  'fun': require('../assets/profile/icons/visited/fun-triangle.png'),
}
