import React from 'react';
import Checkbox from "../../../components/ui/Checkbox";
import Radio from "../../../components/ui/Radio";
import {hankiesIcons, roleIcons, safetyPracticeIcons} from "../../../core/icons";
import Switch from "react-switch";
import CalendarPicker from "../../../components/ui/CalendarPicker";
import moment from "moment";

type BlockType = {
  block: any,
  values: any[],
  onChange: (type_id: number, value: string, multiply?: boolean) => void
}

function Block({block, values, onChange}: BlockType) {
  return (
    <div className="edit-profile-block">
      <span className="edit-profile-block-sub-title">{block.label}</span>
      {['tips', 'sp', 'role'].indexOf(block.type) !== -1 &&
        <BlockCheckboxes block={block} values={values} onChange={onChange}/>
      }
      {block.type === 'lists' &&
        <BlockRadios block={block} values={values} onChange={onChange}/>
      }
      {block.type === 'large_text' &&
        <BlockLargeText block={block} values={values} onChange={onChange}/>
      }
      {(block.type === 'hankies') &&
        <BlockHankies block={block} values={values} onChange={onChange}/>
      }
      {['details', 'details_wh'].indexOf(block.type) !== -1 &&
        <BlockDetails block={block} values={values} onChange={onChange}/>
      }
    </div>
  );
}

function BlockCheckboxes({block, values, onChange}: BlockType) {

  const checkboxValues = values.filter(value => value.type_id === block.id)

  let _public = false
  if (block.public_id !== null && block.public_id !== undefined) {
    const public_value = values.find(value => value.type_id === block.public_id)
    if (public_value !== undefined) {
      _public = parseInt(public_value.value) === 1
    }
  }

  return (
    <>
      <div className="edit-profile-checkboxes">
        {
          block.elements.map((element: any) => {

            let icon : any = null;
            if (block.type === 'sp') {
              icon = <img className="checkbox-icon" src={safetyPracticeIcons[element]}/>
            }
            if (block.type === 'role') {
              icon = <img className="checkbox-icon" src={roleIcons[element]}/>
            }

            return (
              <Checkbox
                label={element}
                checked={checkboxValues.filter((value: any) => value.value === element).length > 0}
                onChange={() => {
                  onChange(block.id, element, true)
                }}
                icon={icon}
              />
            )
          })
        }
      </div>
      {(block.public_id !== null && block.public_id !== undefined) && (
        <div className="edit-profile-checkboxes-switcher">
          <span className="edit-profile-checkboxes-switcher-label">{block.public_label}</span>
          <div className="edit-profile-checkboxes-switcher-right">
            <Switch
              onChange={(checked) => {
                onChange(block.public_id, (+checked).toString(), false)
              }}
              checked={_public}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
        </div>
      )}
    </>
  );
}

function BlockHankies({block, values, onChange}: BlockType) {

  values = values.filter(value => value.type_id === block.id)

  return (
    <div className="edit-profile-hankies">
      {
        block.elements.map((element: any) => {
          return (
            <div className="edit-profile-hankies-element" onClick={() => {
              onChange(block.id, element, true)
            }}>
              {values.filter((value: any) => value.value === element).length > 0 &&  <img src={hankiesIcons.glowIcon} alt="" className="edit-profile-hankies-element-glow"/>}
              <img src={hankiesIcons[element]} alt="" className="edit-profile-hankies-element-icon"/>
            </div>
          )
        })
      }
    </div>
  );
}

function BlockRadios({block, values, onChange}: BlockType) {

  values = values.filter(value => value.type_id === block.id)

  return (
    <div className="edit-profile-checkboxes">
      {
        block.elements.map((element: any) => {
          return (
            <Radio
              label={element}
              checked={values.filter((value: any) => value.value === element).length > 0}
              onChange={() => {
                onChange(block.id, element, false)
              }}
            />
          )
        })
      }
    </div>
  );
}

function BlockLargeText({block, values, onChange}: BlockType) {

  const value = values.find(value => value.type_id === block.id)
  const text = value !== undefined ? value.value : ''

  return (
    <div className="edit-profile-large-text">
      <textarea
        className="edit-profile-large-text-input"
        placeholder={block.placeholder}
        value={text}
        onChange={(event) => {
          onChange(block.id, event.target.value, false)
        }}
      />
    </div>
  );
}

function BlockDetails({block, values, onChange}: BlockType) {

  let date = null
  const date_value = values.find(value => value.type_id === block.date_id)

  if (date_value !== undefined) {

    try {
      date = moment(date_value.value).toDate()
    } catch {
      date = null
    }
  }

  let hey = false
  if (block.hey_id !== undefined && block.hey_id !== null) {
    const hey_value = values.find(value => value.type_id === block.hey_id)
    if (hey_value !== undefined) {
      hey = parseInt(hey_value.value) === 1
    }
  }

  return (
    <div className="edit-profile-details">
      <div className="edit-profile-date">
        <span className="edit-profile-date-label">Birthday</span>
        <div className="edit-profile-date-right">
          <CalendarPicker
            date={date}
            onChange={(date) => {
              onChange(block.date_id, moment(date).format('YYYY-MM-DD'), false)
            }}
          />
        </div>
      </div>
      {block.hey_id !== undefined && block.hey_id !== null && (
        <div className="edit-profile-hey">
          <div className="edit-profile-hey-left">
          <span className="edit-profile-hey-left-text">
            Open to meeting a new friend who identify as straight or bi?
          </span>
            <span className="edit-profile-hey-left-text">
            Meet new friends through our sister app HeyHeyHey
          </span>
          </div>
          <div className="edit-profile-hey-right">
            <Switch
              onChange={(checked) => {
                onChange(block.hey_id, (+checked).toString(), false)
              }}
              checked={hey}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}


export default Block;

