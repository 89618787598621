import React from 'react';



export type ActivationType = {
  email: string | null,
  onBack: () => void
}

function Activation({email, onBack}: ActivationType) {

  return (
    <div className="login-page-content-inner">
      <h1 className="login-page-title">Let's confirm your information</h1>
      <div className="login-page-text">We’ve sent a confirmation email to <b>{email}</b> with a link to confirm your address. <br/>Please check your email and click on the link there. If you don’t find it, please check your spam/junk folder.</div>
      <div className="login-page-submit-area" style={{marginTop: 50}}>
          <span className="login-page-submit-area-back-link" onClick={() => {
            onBack();
          }}>Back</span>
      </div>
    </div>
  );
}

export default Activation;
